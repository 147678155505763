import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Divider,
  Drawer,
  List,
  isWidthDown,
  makeStyles,
} from '@material-ui/core';
import {
  HelpIcon,
  LogoutIcon,
  MoreIcon,
} from '../../icons';
import { useIntl, useSimpleDialog, useWidth } from '../../hooks';
import { barWidth, railWidth } from '../../containers/Layout';
import ProfileCreateDialog from '../ProfileCreateDialog';
import NavItem from './NavItem';
import NavItemList from './NavItemList';
import MoreDrawer from './MoreDrawer';
import ProfileDrawer from './ProfileDrawer';
import { SESSION_TERMINATE_ } from '../../state/session';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  avatar: {
    width: 32,
    height: 32,
  },
  drawer: {
    width: (props) => (props.navType === 'rail' ? railWidth : barWidth),
    zIndex: theme.zIndex.drawer,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    zIndex: theme.zIndex.appBar - 1,
    [theme.breakpoints.down('sm')]: { height: 'auto' },
  },
  drawerOpen: {
    top: 'auto',
    width: barWidth,
    transition: theme.transitions.create(['width', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    top: 'auto',
    transition: theme.transitions.create(['width', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: (props) => (props.navType === 'rail' ? railWidth : 0),
    [theme.breakpoints.up('sm')]: { width: railWidth },
  },
}));

const NavBar = ({ navType, variant, isOpen, drawerHandlers }) => {
  const classes = useStyles({ navType })
  const profile = useSelector((state) => state.session.profile)
  const isStaff = useSelector((state) => state.session.isAuthorized && state.session.isStaff)
  const translate = useIntl()
  const width = useWidth()

  const [isProfileOpen, handleProfileOpen, handleProfileClose] = useSimpleDialog(false)
  const [isNewProfileOpen, handleNewProfileOpen, handleNewProfileClose] = useSimpleDialog(false)
  const [isMoreOpen, handleMoreOpen, handleMoreClose] = useSimpleDialog(false)

  const dispatch = useDispatch()
  const terminateSession = useCallback(() => dispatch(SESSION_TERMINATE_()), [dispatch])

  const mainItems = [
    //{ nav: 'security', icon: <SecurityIcon className={classes.avatar} /> },
    //{ nav: 'settings', icon: <SettingsIcon className={classes.avatar} /> },
    { nav: 'help', icon: <HelpIcon className={classes.avatar} /> },
  ];

  const firstLetterOfAddress = profile.name[0].toUpperCase()

  const {
    handleDrawerOpen,
    handleDrawerClose,
    handleMouseEnter,
    handleMouseLeave,
    handleNavigate,
  } = drawerHandlers

  // eslint-disable-next-line no-unused-vars
  if (!profile) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      <ProfileCreateDialog
        open={isNewProfileOpen}
        onClose={handleNewProfileClose}
      />
      <MoreDrawer
        open={isMoreOpen}
        onToggle={handleMoreClose}
        drawerHandlers={drawerHandlers}
      />
      <ProfileDrawer
        open={isProfileOpen}
        onToggle={handleProfileClose}
        onNewProfileClick={handleNewProfileOpen}
      />
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Drawer
          anchor={isWidthDown('sm', width) ? 'bottom' : 'left'}
          open={isOpen}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        >
          <Box display='flex' flexDirection='column'>
            <Box flexGrow='1'>
              <List>
                <NavItem
                    key='terminate-session'
                    icon={<LogoutIcon className={classes.avatar} />}
                    onClick={terminateSession}
                    primaryText={translate.nav.logout}
                />
                <Divider/>
                <NavItemList
                    items={mainItems}
                    classes={classes}
                    handleNavigate={handleNavigate}
                    showText={isOpen}
                />
                {
                  isStaff
                  &&
                  <>
                    <Divider/>
                    <NavItem
                        icon={<MoreIcon className={classes.avatar} />}
                        onClick={handleMoreOpen}
                        primaryText={translate?.nav?.admin ?? 'Admin'}
                        showText={isOpen}
                    />
                  </>
                }
              </List>
            </Box>
          </Box>
        </Drawer>
      </div>
    </div>
  );
};

NavBar.propTypes = {
  navType: PropTypes.oneOf(['bar', 'rail']).isRequired,
  variant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']).isRequired,
  isOpen: PropTypes.bool.isRequired,
  drawerHandlers: PropTypes.shape({
    handleDrawerOpen: PropTypes.func.isRequired,
    handleDrawerClose: PropTypes.func.isRequired,
    handleMouseEnter: PropTypes.func.isRequired,
    handleMouseLeave: PropTypes.func.isRequired,
    handleNavigate: PropTypes.func.isRequired,
  }).isRequired,
};

export default NavBar;
