import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { Route } from 'react-router';

// code splitting HOCs to improve load time
// Superpowers is deprecated, Reports and Dashboard only used by staff
//
const AdminMints = lazy(() => import('./AdminMints'));

const AdminRouter = () => {
  const isStaff = useSelector(
    (state) => state.session.isAuthorized && state.session.isStaff
  );

  return isStaff ? (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <Route exact path='/admin/mints' component={AdminMints} />
      </Switch>
    </Suspense>
  ) : null;
};

export default AdminRouter;
