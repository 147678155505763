import { ofType } from 'redux-observable';
import { of, map, switchMap, catchError } from 'rxjs';
import { Map } from 'immutable';
import { LinksApiService } from '../services';

// action types
//
export const LINKS_ADDRESS_FETCH = 'LINKS:ADDRESS_FETCH';
export const LINKS_ADDRESS_FETCHED = 'LINKS:ADDRESS_FETCHED';

// action creators
//
export const LINKS_ADDRESS_FETCH_ = (asset_id, new_address = false) => ({
  type: LINKS_ADDRESS_FETCH,
  asset_id,
  new_address,
});
export const LINKS_ADDRESS_FETCHED_ = (asset_id, data) => ({
  type: LINKS_ADDRESS_FETCHED,
  asset_id,
  data,
});

export const reduce = (state = Map(), action) => {
  switch (action.type) {
    default:
      return state;
    case LINKS_ADDRESS_FETCH:
      return state.set(action.asset_id, {
        address: null
      });
    case LINKS_ADDRESS_FETCHED:
      return state.set(action.asset_id, {
        address: action.data ? action.data.address : null
      });
  }
};

export const epics = [
  (action$) =>
    action$.pipe(
      ofType(LINKS_ADDRESS_FETCH),
      switchMap((action) => {
        if (action.new_address) {
          return new LinksApiService().getNew$(action.asset_id).pipe(
            map((response) => LINKS_ADDRESS_FETCHED_(action.asset_id, response)),
            catchError(() => of(LINKS_ADDRESS_FETCHED_(action.asset_id, null)))
          );
        } else {
          return new LinksApiService().getCurrent$(action.asset_id).pipe(
            map((response) => LINKS_ADDRESS_FETCHED_(action.asset_id, response)),
            catchError(() => of(LINKS_ADDRESS_FETCHED_(action.asset_id, null)))
          );
        }
      })
    ),
];
