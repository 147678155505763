import React, { lazy, useState, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { Anon, Auth } from './Own';
import Link from './Link';
import { TransferIcon, RequestIcon, SettingsIcon } from '../icons';
import { useIntl, useSimpleDialog } from '../hooks';
import ProfileDrawer from './layout/ProfileDrawer';
import ProfileCreateDialog from './ProfileCreateDialog';

const SendDialog = lazy(() => import('./SendDialog'));
const RequestDialog = lazy(() => import('./RequestDialog'));
const ProfileListDialog = lazy(() => import('./ProfileListDialog'));

const ProfileBar = ({ editing, onEdit, own, profile }) => {
  const t_ = useIntl();
  const [open, setOpen] = useState(null);
  const location = useLocation();
  const [isProfileDrawerOpen, handleProfileDrawerOpen, handleProfileDrawerClose] = useSimpleDialog(false);
  const [isNewProfileOpen, handleNewProfileOpen, handleNewProfileClose] = useSimpleDialog(false);

  return (
    <>
      <ProfileDrawer
        open={isProfileDrawerOpen}
        onToggle={handleProfileDrawerClose}
        onNewProfileClick={handleNewProfileOpen}
      />
      <ProfileCreateDialog
        open={isNewProfileOpen}
        onClose={handleNewProfileClose}
      />
      <Box py={2} px={1} display='flex'>
        {own ? null : (
          <Box pr={1}>
            <Auth>
              <Button
                variant='text'
                color='primary'
                endIcon={<TransferIcon color='primary' />}
                onClick={() => setOpen('send')}
              >
                {t_.nav.send}
              </Button>
            </Auth>
            <Anon>
              <Link
                to={{ pathname: '/signup', state: { from: location } }}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant='contained'
                  color='primary'
                  endIcon={<TransferIcon />}
                >
                  {t_.nav.send}
                </Button>
              </Link>
            </Anon>
          </Box>
        )}
        <Box flexGrow="1"/>
        {own ? null : (
          <Box pr={1}>
            <Auth>
              <Button
                  variant='text'
                  color='secondary'
                  endIcon={<RequestIcon color='secondary' />}
                  onClick={() => setOpen('request')}>
                {t_.nav.request}
              </Button>
            </Auth>
            <Anon>
              <Link
                  to={{ pathname: '/signup', state: { from: location } }}
                  style={{ textDecoration: 'none' }}>
                <Button
                    variant='contained'
                    color='secondary'
                    endIcon={<RequestIcon />}>
                  {t_.nav.request}
                </Button>
              </Link>
            </Anon>
          </Box>
        )}
        <Auth>
          <Box pr={1}>
            <Button
                disabled={!profile.member?.nodes.length}
                onClick={() => setOpen('members')}
                size='small'
                variant='text'>
              {t_.groups.members.title}
            </Button>
          </Box>
          <Box>
            <Button
                disabled={!profile.membership?.nodes.length}
                onClick={() => setOpen('affiliations')}
                size='small'
                variant='text'>
              {t_.profile.affiliations}
            </Button>
          </Box>
          {own ? (
            <Box>
              <Button
                  disabled={!profile.visitors?.nodes.length}
                  onClick={() => setOpen('visitors')}
                  size='small'
                  variant='text'>
                {t_.profile.visitors}
              </Button>
            </Box>
          ) : null}
          {own ? (
            <IconButton
                disabled={editing}
                size='small'
                onClick={onEdit}>
              <SettingsIcon fontSize='small' />
            </IconButton>
          ) : null}
          {own ? (
            <Button
                onClick={handleProfileDrawerOpen}
                size="small"
                variant="text">
              switch to
            </Button>
          ) : null}
        </Auth>
      </Box>
      <Suspense fallback={<CircularProgress />}>
        {open === 'send' && (
          <SendDialog
            to={profile}
            open={open === 'send'}
            onCancel={() => setOpen(null)}
            onError={() => setOpen(null)}
            onSuccess={() => setOpen(null)}
          />
        )}
        {open === 'request' && (
          <RequestDialog
            from={profile}
            open={open === 'request'}
            onCancel={() => setOpen(null)}
            onError={() => setOpen(null)}
            onSuccess={() => setOpen(null)}
          />
        )}
        {open === 'members' && (
          <ProfileListDialog
            onClose={() => setOpen(null)}
            open={open === 'members'}
            profiles={profile.member?.nodes.map((m) => m.profile)}
            title={t_.groups.members.title}
          />
        )}
        {open === 'affiliations' && (
          <ProfileListDialog
            onClose={() => setOpen(null)}
            open={open === 'affiliations'}
            profiles={profile.membership?.nodes.map((m) => m.group)}
            title={t_.profile.affiliations}
          />
        )}
        {open === 'visitors' && (
          <ProfileListDialog
            onClose={() => setOpen(null)}
            open={open === 'visitors'}
            profiles={profile.visitors?.nodes.map((m) => m.profile)}
            title={t_.profile.visitors}
          />
        )}
      </Suspense>
    </>
  );
};

export default ProfileBar;
