import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import AnonHome from './AnonHome';
import Route from '../components/AuthRoute';
import { Route as AnonRoute } from 'react-router';
import { useWidth } from '../hooks';
import useNavDrawer from '../hooks/layout/useNavDrawer';
import BottomNav from './BottomNav';
import NavBar from '../components/layout/NavBar';

// code splitting HOCs to improve load time
// Superpowers is deprecated, Reports and Dashboard only used by staff
//
const Activity = lazy(() => import('./Activity'));
const AgentHome = lazy(() => import('../views/agent/AgentHome'));
const AgentCollect = lazy(() => import('../views/agent/AgentCollect'));
const AnonProfile = lazy(() => import('./AnonProfile'));
const Asset = lazy(() => import('./Asset'));
const AssetSearch = lazy(() => import('./AssetSearch'));
const Burn = lazy(() => import('./Burn'));
const BurnMenu = lazy(() => import('./BurnMenu'));
const Dashboard = lazy(() => import('./Dashboard'));
const DepthView = lazy(() => import('./DepthView'));
const Documents = lazy(() => import('./Documents'));
const Explore = lazy(() => import('./Explore'));
const Express = lazy(() => import('./Express'));
const Friends = lazy(() => import('./Friends'));
const Groups = lazy(() => import('./Groups'));
const Help = lazy(() => import('./Help'));
const Home = lazy(() => import('./Home'));
const LegacyVerifyIdentity = lazy(() => import('./LegacyVerifyIdentity'));
const LegacyVerifyWealth = lazy(() => import('./LegacyVerifyWealth'));
const Mint = lazy(() => import('./Mint'));
const Mint2 = lazy(() => import('./Mint2'));
const MintMenu = lazy(() => import('./MintMenu'));
const MusingView = lazy(() => import('./MusingView'));
const PerkView = lazy(() => import('./PerkView'));
const Pocket = lazy(() => import('./Pocket'));
const Preview = lazy(() => import('./Preview'));
const Private = lazy(() => import('./Private'));
const Profile = lazy(() => import('./Profile'));
const Reports = lazy(() => import('./Reports'));
const Request = lazy(() => import('./Request'));
const RequestMenu = lazy(() => import('./RequestMenu'));
const Rewards = lazy(() => import('./Rewards'));
const Search = lazy(() => import('./Search'));
const SecuritySettings = lazy(() => import('./SecuritySettings'));
const Send = lazy(() => import('./Send'));
const SendMenu = lazy(() => import('./SendMenu'));
//const SessionTimeout = lazy(() => import('../components/SessionTimeout'));
import SessionTimeout from '../components/SessionTimeout';
const Settings = lazy(() => import('./Settings'));
const Street = lazy(() => import('./Street'));
const StreetAd = lazy(() => import('./StreetAd'));
const Subscribe = lazy(() => import('./Subscribe'));
const Superpowers = lazy(() => import('./Superpowers'));
const Tools = lazy(() => import('./Tools'));
const VenueView = lazy(() => import('./VenueView'));
const WhichTrader = lazy(() => import('./WhichTrader'));

const Router = () => {
  const isAuthenticated = useSelector((state) => state.session.isAuthorized);
  // eslint-disable-next-line no-unused-vars
  const isStaff = useSelector(
    (state) => state.session.isAuthorized && state.session.isStaff
  );
  // eslint-disable-next-line no-unused-vars
  const userId = useSelector((state) =>
    state.session.user ? state.session.user.id : undefined
  );
  const width = useWidth();
  const { isOpen, variant, handlers } = useNavDrawer('bar');
  const needsPassword = useSelector(
    (state) =>
      window.location.pathname !== '/security' &&
      state.session.isAuthorized &&
      !state.session.isPasswordSet
  )
  const needsProfile = useSelector(
    (state) =>
      window.location.pathname !== '/security' &&
      window.location.pathname !== '/profile' &&
      state.session.isAuthorized &&
      !state.session.isProfileComplete
  )

  if (needsPassword) {
    return <Redirect to='/security' />;
  }

  if (needsProfile) {
    return <Redirect to='/profile' />;
  }

  console.log(`isAuthenticated ${isAuthenticated} ${userId} ${isStaff}`);
  
  return (
    <Suspense fallback={<CircularProgress />}>
      <Switch>
        {isAuthenticated ? null : (
          <AnonRoute exact path='/home' component={AnonHome} />
        )}
        {isAuthenticated ? null : (
          <AnonRoute exact path='/help' component={Help} />
        )}
        {isAuthenticated ? null : (
          <AnonRoute exact path='/@:name/' component={AnonProfile} />
        )}
        {isAuthenticated ? null : (
          <AnonRoute exact path='/@:sponsor/venue/:id/' component={VenueView} />
        )}
        {isAuthenticated ? null : (
          <AnonRoute exact path='/@:sponsor/perk/:id/' component={PerkView} />
        )}
        {isAuthenticated ? null : (
          <AnonRoute exact path='/@:sponsor/:id/' component={MusingView} />
        )}
        <Route>
          <SessionTimeout />
          <BottomNav drawerHandlers={handlers} />
          <NavBar
            navType="bar"
            variant={variant}
            isOpen={isOpen}
            drawerHandlers={handlers}
          />
            <Suspense fallback={<CircularProgress />}>
              <Switch>
                <Route exact path='/home' component={Home} />
                <Route exect path='/agent/collect/:digital/' component={AgentCollect} />
                <Route exect path='/agent/' component={AgentHome} />
                <Route exact path='/explore' component={Explore} />
                <Route exact path='/friends' component={Friends} />
                <Route exact path='/max' component={WhichTrader} />
                <Route exact path='/search' component={Search} />
                <Route exact path='/pocket' component={Pocket} />
                <Route exact path='/activity' component={Activity} />
                <Route exact path='/activity/:filter/' component={Activity} />
                <Route exact path='/@:name/' component={Profile} />
                <Route exact path='/profile' component={Profile} />
                <Route exact path='/@:sponsor/venue/:id/' component={VenueView} />
                <Route exact path='/@:sponsor/perk/:id/' component={PerkView} />
                <Route exact path='/@:name/:id/' component={MusingView} />
                <Route exact path='/groups' component={Groups} />
                <Route exact path='/mint/:digital/' component={Mint} />
                <Route exact path='/mint2/:digital/' component={Mint2} />
                <Route exact path='/mint' component={MintMenu} />
                <Route exact path='/burn/:digital/' component={Burn} />
                <Route exact path='/burn' component={BurnMenu} />
                <Route exact path='/request/:digital/' component={Request} />
                <Route exact path='/request' component={RequestMenu} />
                <Route exact path='/send/:digital/' component={Send} />
                <Route exact path='/send' component={SendMenu} />
                <Route exact path='/max/:baseCode/:quoteCode/depth' component={DepthView} />
                <Route exact path='/express' component={Express} />
                <Route exact path='/private' component={Private} />
                <Route exact path='/rewards' component={Rewards} />
                <Route exact path='/street/ads/:side/:digital/' component={Street} />
                <Route exact path='/street/ad/:id/' component={StreetAd} />
                <Route exact path='/street' component={Street} />
                <Route exact path='/tools' component={Tools} />
                <Route exact path='/help' component={Help} />
                <Route exact path='/assets/:slug/' component={AssetSearch} />
                <Route exact path='/asset/:assetCode/' ><Asset /></Route>
                <Route exact path='/subscribe/:assetCode/' component={Subscribe} />
                <Route exact path='/superpowers/:assetCode/' component={Superpowers} />
                <Route exact path='/reports/' component={Reports} />
                <Route exact path='/dashboard/' component={Dashboard} />
                <Route exact path='/settings/' component={Settings} />
                <Route exact path='/security/' component={SecuritySettings} />
                <Route exact path='/docs' component={Documents} />
                <Route exact path='/legacy/verify_identity' component={LegacyVerifyIdentity} />
                <Route exact path='/legacy/verify_wealth' component={LegacyVerifyWealth} />
                <Route exact path='/preview/:assetCode/' component={Preview} />
              </Switch>
            </Suspense>
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Router;
