/**
 * @generated SignedSource<<d830d30c908d888088a3b11c9d50bfd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsNotificationsOrderBy = "ACTION_OBJECT_CONTENT_TYPE_ID_ASC" | "ACTION_OBJECT_CONTENT_TYPE_ID_DESC" | "ACTION_OBJECT_OBJECT_ID_ASC" | "ACTION_OBJECT_OBJECT_ID_DESC" | "ACTOR_CONTENT_TYPE_ID_ASC" | "ACTOR_CONTENT_TYPE_ID_DESC" | "ACTOR_OBJECT_ID_ASC" | "ACTOR_OBJECT_ID_DESC" | "DATA_ASC" | "DATA_DESC" | "DELETED_ASC" | "DELETED_DESC" | "DESCRIPTION_ASC" | "DESCRIPTION_DESC" | "EMAILED_ASC" | "EMAILED_DESC" | "ID_ASC" | "ID_DESC" | "LEVEL_ASC" | "LEVEL_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "PUBLIC_ASC" | "PUBLIC_DESC" | "RECIPIENT_ID_ASC" | "RECIPIENT_ID_DESC" | "TARGET_CONTENT_TYPE_ID_ASC" | "TARGET_CONTENT_TYPE_ID_DESC" | "TARGET_OBJECT_ID_ASC" | "TARGET_OBJECT_ID_DESC" | "TIMESTAMP_ASC" | "TIMESTAMP_DESC" | "UNREAD_ASC" | "UNREAD_DESC" | "VERB_ASC" | "VERB_DESC" | "%future added value";
export type NotificationsNotificationCondition = {
  actionObjectContentTypeId?: number | null;
  actionObjectObjectId?: string | null;
  actorContentTypeId?: number | null;
  actorObjectId?: string | null;
  data?: string | null;
  deleted?: boolean | null;
  description?: string | null;
  emailed?: boolean | null;
  level?: string | null;
  public?: boolean | null;
  recipientId?: number | null;
  rowId?: number | null;
  targetContentTypeId?: number | null;
  targetObjectId?: string | null;
  timestamp?: any | null;
  unread?: boolean | null;
  verb?: string | null;
};
export type PagedFeedQuery$variables = {
  count: number;
  cursor?: any | null;
  filter: NotificationsNotificationCondition;
  id: string;
  orderBy?: ReadonlyArray<NotificationsNotificationsOrderBy> | null;
};
export type PagedFeedQuery$data = {
  readonly stub: {
    readonly " $fragmentSpreads": FragmentRefs<"PagedFeed_stub">;
  } | null;
};
export type PagedFeedQuery = {
  response: PagedFeedQuery$data;
  variables: PagedFeedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v6/*: any*/)
],
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "model",
    "storageKey": null
  },
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PagedFeedQuery",
    "selections": [
      {
        "alias": "stub",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "PagedFeed_stub"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "PagedFeedQuery",
    "selections": [
      {
        "alias": "stub",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "feed",
                "args": (v9/*: any*/),
                "concreteType": "NotificationsNotificationsConnection",
                "kind": "LinkedField",
                "name": "notificationsNotificationsByActorContentTypeId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NotificationsNotificationsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NotificationsNotification",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rowId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "data",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "verb",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timestamp",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unread",
                            "storageKey": null
                          },
                          {
                            "alias": "targetId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "targetObjectId",
                            "storageKey": null
                          },
                          {
                            "alias": "target",
                            "args": null,
                            "concreteType": "DjangoContentType",
                            "kind": "LinkedField",
                            "name": "djangoContentTypeByTargetContentTypeId",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": "actionId",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "actionObjectObjectId",
                            "storageKey": null
                          },
                          {
                            "alias": "action",
                            "args": null,
                            "concreteType": "DjangoContentType",
                            "kind": "LinkedField",
                            "name": "djangoContentTypeByActionObjectContentTypeId",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "feed",
                "args": (v9/*: any*/),
                "filters": [
                  "condition",
                  "orderBy"
                ],
                "handle": "connection",
                "key": "ActivityStream_feed",
                "kind": "LinkedHandle",
                "name": "notificationsNotificationsByActorContentTypeId"
              }
            ],
            "type": "DjangoContentType",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fc8295f2e0dca69e250e80f788711f99",
    "id": null,
    "metadata": {},
    "name": "PagedFeedQuery",
    "operationKind": "query",
    "text": "query PagedFeedQuery(\n  $id: ID!\n  $filter: NotificationsNotificationCondition!\n  $count: Int!\n  $cursor: Cursor\n  $orderBy: [NotificationsNotificationsOrderBy!]\n) {\n  stub: node(id: $id) {\n    __typename\n    ...PagedFeed_stub_1FfpYs\n    id\n  }\n}\n\nfragment PagedFeed_stub_1FfpYs on DjangoContentType {\n  id\n  feed: notificationsNotificationsByActorContentTypeId(condition: $filter, first: $count, after: $cursor, orderBy: $orderBy) {\n    edges {\n      node {\n        id\n        rowId\n        data\n        description\n        verb\n        timestamp\n        unread\n        targetId: targetObjectId\n        target: djangoContentTypeByTargetContentTypeId {\n          model\n          id\n        }\n        actionId: actionObjectObjectId\n        action: djangoContentTypeByActionObjectContentTypeId {\n          model\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "563134f57bb47122d753919ffabd8046";

export default node;
