import React from 'react';
import { Box, Avatar } from '@material-ui/core';
import { 
  InfoOutlined as InfoIcon,
  WarningOutlined as WarningIcon,
  ErrorOutlined as ErrorIcon,
  CheckCircleOutline as SuccessIcon,
  HelpOutline as HelpIcon
} from '@material-ui/icons';
import ActivityBox from './ActivityBox';
import Markdown from '../Markdown';
import type { ActivityProps } from './types';

interface MessageActivityProps extends ActivityProps {
  message: string;
  messageType?: 'info' | 'warning' | 'error' | 'success' | 'help';
}

/**
 * A simple activity component that displays a message which may include hyperlinks.
 * The message can be formatted with markdown syntax, allowing for hyperlinks and basic formatting.
 */
const MessageActivity: React.FC<MessageActivityProps> = ({
  time,
  message,
  messageType = 'info',
  unread,
  onRead,
}) => {
  // Map message types to icons
  const iconMap = {
    info: <InfoIcon color="primary" />,
    warning: <WarningIcon style={{ color: '#ff9800' }} />,
    error: <ErrorIcon color="error" />,
    success: <SuccessIcon style={{ color: '#4caf50' }} />,
    help: <HelpIcon color="action" />
  };

  return (
    <ActivityBox time={time} unread={unread} onRead={onRead}>
      <Box p={1}>
        <Avatar>{iconMap[messageType]}</Avatar>
      </Box>
      <Box p={1} flexGrow={1}>
        <Markdown>{message}</Markdown>
      </Box>
    </ActivityBox>
  );
};

export default MessageActivity;
