import React from 'react';
import { FormControl, FormGroup, FormLabel } from '@material-ui/core';
import ReactCodeInput from 'react-code-input';

const NumericCodeInput = ({label, ...props}) => {
  const styled = {
    inputStyle: {
      fontFamily: 'monospace',
      margin:  '4px',
      MozAppearance: 'textfield',
      width: '36px',
      borderRadius: '3px',
      fontSize: '24px',
      height: '36px',
      paddingLeft: '7px',
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin:  '4px',
      MozAppearance: 'textfield',
      width: '36px',
      borderRadius: '3px',
      fontSize: '24px',
      height: '36px',
      paddingLeft: '7px',
      border: '1px solid red'
    },
    ...props
  };

  //<Typography variant='caption'>{label}</Typography>
  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>
        {label}
      </FormLabel>
      <FormGroup>
        <ReactCodeInput type="number" inputMode="numeric" {...styled} />
      </FormGroup>
    </FormControl>
  );
};

export default NumericCodeInput;
