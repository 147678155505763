import { BaseApiService } from './BaseApiService';

class NotificationApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/activity/', access, onError);
  }

  async captain_manage_mbr(notificationId) {
    return await this.http.postFetch(`captain_manage_mbr/${notificationId}/`, {});
  }
}
export default NotificationApiService; 