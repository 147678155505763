import React from 'react';
import { DateTime } from 'luxon';
import { Box, Typography, Paper } from '@material-ui/core';
import MessageActivity from './MessageActivity';
import type { AssetsState, AssetsStateValue } from '../../types/store';

/**
 * Example component demonstrating how to use MessageActivity
 */
const MessageActivityExample: React.FC = () => {
  // Mock function for the onRead callback
  const handleRead = () => {
    console.log('Message marked as read');
  };

  // Create a mock assets map for the examples
  const mockAssets: AssetsState = new Map();
  
  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h6" gutterBottom>
          Message Activity Examples
        </Typography>
        
        {/* Info message example */}
        <MessageActivity
          time={DateTime.local()}
          message="This is an information message with a [link](https://example.com)."
          messageType="info"
          unread={true}
          onRead={handleRead}
          // These props are required by ActivityProps but not used in this component
          assets={mockAssets}
          locale="en"
          actionId={0}
        />
        
        {/* Warning message example */}
        <MessageActivity
          time={DateTime.local()}
          message="This is a warning message with **bold text**."
          messageType="warning"
          unread={false}
          onRead={handleRead}
          // These props are required by ActivityProps but not used in this component
          assets={mockAssets}
          locale="en"
          actionId={0}
        />
        
        {/* Error message example */}
        <MessageActivity
          time={DateTime.local()}
          message="This is an error message with a list:\n- Item 1\n- Item 2"
          messageType="error"
          unread={true}
          onRead={handleRead}
          // These props are required by ActivityProps but not used in this component
          assets={mockAssets}
          locale="en"
          actionId={0}
        />
        
        {/* Success message example */}
        <MessageActivity
          time={DateTime.local()}
          message="This is a success message with *italic text*."
          messageType="success"
          unread={false}
          onRead={handleRead}
          // These props are required by ActivityProps but not used in this component
          assets={mockAssets}
          locale="en"
          actionId={0}
        />
        
        {/* Help message example */}
        <MessageActivity
          time={DateTime.local()}
          message="Need help? Visit our [help center](https://help.example.com) or [contact support](mailto:support@example.com)."
          messageType="help"
          unread={true}
          onRead={handleRead}
          // These props are required by ActivityProps but not used in this component
          assets={mockAssets}
          locale="en"
          actionId={0}
        />
      </Box>
    </Paper>
  );
};

export default MessageActivityExample;
