import React, { Suspense, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { get } from '../../utils/utils';
import DateDivider from './DateDivider';
import HandlePrivateOfferActivity from './HandlePrivateOfferActivity';
import HandleRequestActivity from './HandleRequestActivity';
import StreetTicketActivity from './StreetTicketActivity';
import TransferActivity from './TransferActivity';
import { PROFILES_DECREMENT_UNREAD_ } from '../../state/profiles';

const MessageActivity = React.lazy(() => import('./MessageActivity'));

const AcceptedRequestActivity = React.lazy(() =>
  import('./AcceptedRequestActivity')
);
const AltMintCompleteActivity = React.lazy(() =>
  import('./AltMintCompleteActivity')
);
const BurnCompleteActivity = React.lazy(() => import('./BurnCompleteActivity'));
const BurnFailActivity = React.lazy(() => import('./BurnFailActivity'));
const BurnRequestActivity = React.lazy(() => import('./BurnRequestActivity'));
const CanceledRequestActivity = React.lazy(() =>
  import('./CanceledRequestActivity')
);
const CryptoMintActivity = React.lazy(() => import('./CryptoMintActivity'));
const IdxActivity = React.lazy(() => import('./IdxActivity'));
const IssueOfferSubscription = React.lazy(() =>
  import('./IssueOfferSubscription')
);
const LegacyBurnCompleteActivity = React.lazy(() =>
  import('./LegacyBurnCompleteActivity')
);
const LegacyBurnFailActivity = React.lazy(() =>
  import('./LegacyBurnFailActivity')
);
const LegacyBurnRequestActivity = React.lazy(() =>
  import('./LegacyBurnRequestActivity')
);
const LegacyMintCompleteActivity = React.lazy(() =>
  import('./LegacyMintCompleteActivity')
);
const LegacyMintFailActivity = React.lazy(() =>
  import('./LegacyMintFailActivity')
);
const LegacyMintPledgeActivity = React.lazy(() =>
  import('./LegacyMintPledgeActivity')
);
const LegacyTalentActivity = React.lazy(() => import('./LegacyTalentActivity'));
const MembershipActivity = React.lazy(() => import('./MembershipActivity'));
const MintCompleteActivity = React.lazy(() => import('./MintCompleteActivity'));
const MintFailActivity = React.lazy(() => import('./MintFailActivity'));
const MintPledgeActivity = React.lazy(() => import('./MintPledgeActivity'));
const PrivateOfferActivity = React.lazy(() => import('./PrivateOfferActivity'));
const RequestActivity = React.lazy(() => import('./RequestActivity'));
const RewardActivity = React.lazy(() => import('./RewardActivity'));
const TalentActivity = React.lazy(() => import('./TalentActivity'));
const UnknownActivity = React.lazy(() => import('./UnknownActivity'));
const VestingActivity = React.lazy(() => import('./VestingActivity'));

const Lazy = ({ children }) => (
  <Suspense fallback={<LinearProgress />}>{children}</Suspense>
);

export const ActivityRow = (props) => {
  const dispatch = useDispatch();
  const activeProfileId = useSelector((state) => state.session.profile.id);
  const decrementCount = useCallback(
    (profileId) => dispatch(PROFILES_DECREMENT_UNREAD_(profileId)),
    [dispatch]
  );

  const [unread, setUnread] = useState(props.unread);

  const handleRead = () => {
    get(`/api/activity/${props.notificationId}/touch`)
      .then((r) => r.ok && setUnread(false))
      .then(decrementCount(activeProfileId));
  };

  const factory = (props) => {
    if (props.description) {
      return (
        <Lazy>
          <UnknownActivity {...props} />
        </Lazy>
      );
    }
    switch (props.type) {
      case 'time':
        return <DateDivider {...props} />;
      case 'send-to':
      case 'send-from':
        return <TransferActivity {...props} />;
      case 'reward':
      case 'rewarded_by_referral':
      case 'rewarded_by_registration':
        return (
          <Lazy>
            <RewardActivity {...props} />
          </Lazy>
        );
      case 'unload_hold':
        return (
          <Lazy>
            {props.actionId ? (
              <BurnRequestActivity {...props} />
            ) : (
              <LegacyBurnRequestActivity {...props} />
            )}
          </Lazy>
        );
      case 'unload_complete':
        return (
          <Lazy>
            {props.actionId ? (
              <BurnCompleteActivity {...props} />
            ) : (
              <LegacyBurnCompleteActivity {...props} />
            )}
          </Lazy>
        );
      case 'load_request':
        return (
          <Lazy>
            {props.actionId ? (
              <MintPledgeActivity {...props} />
            ) : (
              <LegacyMintPledgeActivity {...props} />
            )}
          </Lazy>
        );
      case 'mint_complete':
        return (
          <Lazy>
            {props.actionId ? (
              <MintCompleteActivity {...props} />
            ) : (
              <LegacyMintCompleteActivity {...props} />
            )}
          </Lazy>
        );
      case 'load_complete':
        return (
          <Lazy>
            <AltMintCompleteActivity {...props} />
          </Lazy>
        );
      case 'load_request_bitcoin':
        return (
          <Lazy>
            <CryptoMintActivity {...props} />
          </Lazy>
        );
      case 'load_fail':
        return (
          <Lazy>
            {props.actionId ? (
              <MintFailActivity {...props} />
            ) : (
              <LegacyMintFailActivity {...props} />
            )}
          </Lazy>
        );
      case 'unload_fail':
        return (
          <Lazy>
            {props.actionId ? (
              <BurnFailActivity {...props} />
            ) : (
              <LegacyBurnFailActivity {...props} />
            )}
          </Lazy>
        );
      case 'request-to':
        return (
          <Lazy>
            <RequestActivity {...props} />
          </Lazy>
        );
      case 'request-from':
        return <HandleRequestActivity {...props} />;
      case 'cancel-from':
      case 'cancel-to':
        return (
          <Lazy>
            <CanceledRequestActivity {...props} />
          </Lazy>
        );
      case 'sell-to':
      case 'buy-to':
        return (
          <Lazy>
            <PrivateOfferActivity {...props} />
          </Lazy>
        );
      case 'sell-from':
      case 'buy-from':
        return <HandlePrivateOfferActivity {...props} />;
      case 'accepted_by_to':
      case 'accepted_by_from':
        return (
          <Lazy>
            <AcceptedRequestActivity {...props} />
          </Lazy>
        );
      case 'talent-genesis':
        return (
          <Lazy>
            {props.actionId ? (
              <TalentActivity {...props} />
            ) : (
              <LegacyTalentActivity {...props} />
            )}
          </Lazy>
        );
      case 'qualified_grant':
      case 'reward_vested':
      case 'registration_reward_vested':
      case 'referral_reward_vested':
        return (
          <Lazy>
            <VestingActivity {...props} />
          </Lazy>
        );
      case 'evidence_accepted':
      case 'id_verification_approved':
      case 'iw_verification_approved':
      case 'iw_verification_pending':
      case 'evidence_rejected':
      case 'id_verification_denied':
      case 'iw_verification_denied':
        return (
          <Lazy>
            <IdxActivity {...props} />
          </Lazy>
        );
      case 'street_ticket_created':
        return <StreetTicketActivity {...props} />;
      case 'ino_subscribed':
        return (
          <Lazy>
            <IssueOfferSubscription {...props} />
          </Lazy>
        );
      /*
          'ino_subscribed_bonus': {
              'format': "You've been awarded {ino_bonus_quantity} {ino_bonus_asset} for purchasing {ino_quantity} {ino_asset}.",
              'icon': 'approved.png',
              'action': False,
          }
      */
      /*
          TBD:  I think we're using the verb here in the wrong way.

                The switch should be on the action object class rather than the verb so that
                the activity works regardless of verb and we don't need this huge switch statement.

                But to fix this we need to address it at the origin of the notificationId
                All notifications should go through some model class that is reflected by
                a js/React activity class here.
                
                Most db models would reflect 1:1 as finite state machines and the verb
                represents the name of the current state as the data param captures the
                new state data at the time of the state transition.

                Otherwise we have to pile on all these distinct cases and rewrite this
                logic every time an FSM model is enhanced to gain/remove a state.
      */
      case 'membership-pending':
      case 'membership-active':
      case 'membership-active-renewal':
      case 'membership-declined':
      case 'membership-expired':
      case 'membership-canceled':
      case 'membership-banned':
        return (
          <Lazy>
            <MembershipActivity {...props} />
          </Lazy>
        );
      case 'message':
        return (
          <Lazy>
            <MessageActivity {...props} />
          </Lazy>
        );
      default:
        return (
          <Lazy>
            <UnknownActivity {...props} />
          </Lazy>
        );
    }
  };

  const factoryProps = { ...props, onRead: handleRead, unread };

  return factory(factoryProps);
};
