import React from 'react';
import { DateTime } from 'luxon';
import { Button } from '@material-ui/core';
import OnVisible from 'react-on-visible';
import { ActivityRow } from './ActivityRow';
import { useIntl, useLang } from '../../hooks';

export const Activities = (props) => {
  const { assets, profile, push, stub } = props;
  const translate = useIntl();
  const locale = useLang();

  const _loadMore = () => {
    if (!props.relay.hasMore() || props.relay.isLoading()) {
      return;
    }

    props.relay.loadMore(
      34,
      // fetch next items
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div>
      {stub &&
        stub.feed.edges
          .reduce(
            (acc, edge) => {
              const time = DateTime.fromISO(edge.node.timestamp).toLocal();
              const date = 10000 * time.year + 100 * time.month + time.day;
              
              // Parse data field
              const parsedData = edge.node.data ? JSON.parse(edge.node.data)['data'] : {};
              const { currency } = parsedData;
              
              // Skip if currency is not LUSD or USD
              if (currency !== 'LUSD' && currency !== 'USD') {
                return acc;
              }

              // Get IDs
              const actionId = edge.node.actionId;
              const data_id = parsedData.id;
              const profileName = parsedData.profile_name;

              // Check if we already have entries with these IDs
              const existingDateIndex = acc.all.findIndex(item => 
                item.type === 'time' && item.date === date
              );
              
              let isOwner = profileName === profile?.name;
              if(profileName === undefined)
                isOwner = true;
              // Create the new item
              const item = {
                key: edge.node.id,
                notificationId: parseInt(edge.node.rowId),
                time,
                type: edge.node.verb,
                action: edge.node.action?.model || null,
                actionId: actionId ? parseInt(actionId) : null,
                data_id: data_id,
                owner: isOwner,
                targetId: edge.node.targetId ? parseInt(edge.node.targetId) : null,
                description: edge.node.description,
                unread: edge.node.unread,
                instigator: isOwner ? '' : profileName,
                ...parsedData,
              };

              // Handle date grouping and item insertion
              if (existingDateIndex === -1) {
                // New date, add date marker
                acc.all.push({
                  key: `date-${edge.node.id}`,
                  time,
                  type: 'time',
                  date: date
                });
              }

              if (data_id && !isOwner) {
                  
                // Validate notification type
                const validTypes = ['unload_hold', 'mint_complete', 'unload_fail', 'unload_complete', 'load_request', 'load_fail'];
                if (!validTypes.includes(item.type)) {
                  return acc;
                }

                // For non-owner notifications with data_id, check for existing ones
                const existingItemIndex = acc.all.findIndex(item => 
                  item.type !== 'time' && 
                  item.data_id === data_id && 
                  item.owner === false
                );

                if (existingItemIndex !== -1) {
                  // Update existing item if newer
                  const existingTime = DateTime.fromISO(acc.all[existingItemIndex].time);
                  if (time > existingTime) {
                    acc.all[existingItemIndex] = item;
                  }
                } else {
                  // Add new item
                  acc.all.push(item);
                }
              } else {
                // For owner notifications or notifications without data_id, just add them
                acc.all.push(item);
              }

              return acc;
            },
            /* initial values */ {
              all: [],
            }
          )
          .all.map((item) => {
            return (
              <ActivityRow
                key={item.key}
                assets={assets}
                profile={profile}
                push={push}
                locale={locale}
                {...item}
              />
            );
          })}
      <OnVisible
        bounce={true}
        onChange={(isVisible) => isVisible && _loadMore()}
      >
        <Button fullWidth onMouseOver={() => _loadMore()}>
          {translate.nav.more}
        </Button>
      </OnVisible>
    </div>
  );
};
