import React, { useEffect, useState, createContext, useContext } from 'react';

interface BlockchainInfoContextType {
  currentBlockHeight: number;
  subscribe: () => void;
  unsubscribe: () => void;
  // Future fields can be added here, like:
  // btcPrice: number;
  // networkHashRate: number;
  // etc.
}

const BlockchainInfoContext = createContext<BlockchainInfoContextType>({ 
  currentBlockHeight: 0,
  subscribe: () => {},
  unsubscribe: () => {},
});

export const BlockchainInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentBlockHeight, setCurrentBlockHeight] = useState<number>(0);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const startFetching = () => {
    const fetchBlockchainInfo = async () => {
      try {
        const response = await fetch('https://blockchain.info/q/getblockcount');
        const height = await response.text();
        setCurrentBlockHeight(parseInt(height, 10));
      } catch (error) {
        console.error('Failed to fetch blockchain info:', error);
      }
    };

    fetchBlockchainInfo(); // Initial fetch
    const interval = setInterval(fetchBlockchainInfo, 600000); // 10 minutes
    setIntervalId(interval);
  };

  const stopFetching = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const subscribe = () => {
    setSubscriberCount(prev => {
      const newCount = prev + 1;
      if (newCount === 1) { // First subscriber
        startFetching();
      }
      return newCount;
    });
  };

  const unsubscribe = () => {
    setSubscriberCount(prev => {
      const newCount = prev - 1;
      if (newCount === 0) { // Last subscriber
        stopFetching();
      }
      return newCount;
    });
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return (
    <BlockchainInfoContext.Provider value={{ currentBlockHeight, subscribe, unsubscribe }}>
      {children}
    </BlockchainInfoContext.Provider>
  );
};

export const useBlockchainInfo = () => useContext(BlockchainInfoContext);

export function withBlockchainInfo<P>(
  WrappedComponent: React.ComponentType<P & Omit<BlockchainInfoContextType, 'subscribe' | 'unsubscribe'>>
) {
  return function WithBlockchainInfoComponent(props: P) {
    const { currentBlockHeight, subscribe, unsubscribe } = useBlockchainInfo();

    useEffect(() => {
      subscribe();
      return () => unsubscribe();
    }, []);

    return <WrappedComponent {...props} currentBlockHeight={currentBlockHeight} />;
  };
} 